import React from "react";
import * as styles from "./NotFoundPage.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { SiteMetadataHead } from "../../components/Shared/SiteMetadataHead";

const NotFoundPageTemplate = (props: any) => {
    const data = useStaticQuery(
        graphql`
            query NotFoundPageTemplate {
                strapiNotFoundPage {
                    heading
                    tagline {
                        paragraphHTML
                    }
                }
            }
        `
    );

    const { strapiNotFoundPage } = data;

    const { heading, tagline } = strapiNotFoundPage;
    let random = Math.floor(Math.random() * tagline.length);

    return (
        <>
            <section key={"hero"} className={styles.heroWrapper}>
                <div className="mainBox">
                    <h1 className="font-serif">{heading}</h1>
                </div>
            </section>

            <div className={styles.contentWrapper}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: tagline[random].paragraphHTML || tagline[random].paragraph,
                    }}></div>
            </div>
        </>
    );
};

export default NotFoundPageTemplate;

export function Head({ pageContext }: any) {
    const { seo } = pageContext;

    return (
        <SiteMetadataHead>
            <title id="site-title">{seo.title}</title>
            <meta id="desc" name="description" content={seo.description} />
        </SiteMetadataHead>
    );
}
